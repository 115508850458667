import React from 'react';
import { Shape, Circle, Constraint } from 'react-matter-js';
import { useLetter } from '../features/words/hooks';
import { letterSize } from '../features/words/Letter';
import { sSize, debug } from '../util';

const Logo = () => {
  const shapes = useLetter();
  const hull = shapes?.get('hull');
  const logo = shapes?.get('logo');
  const size = sSize.max * letterSize;

  if (!hull) {
    return null;
  }

  const x = size / 2 + sSize.max * 1e-2;
  const y = size / 2 + sSize.max * 1e-2;
  return (
    <>
      <Constraint
        render={{
          visible: debug,
        }}
        stiffness={0.01}
        length={5}
      >
        <Circle
          x={x}
          y={y}
          radius={size / 2}
          options={{
            isSensor: true,
            isStatic: true,
            render: { visible: debug },
          }}
        />
        <Shape
          clone
          x={x}
          y={y}
          width={size}
          height={size}
          hull={hull.shape}
          sprite={logo.shape}
          options={{
            render: { visible: debug },
            collisionFilter: {
              category: 0x0002,
            },
          }}
        />
      </Constraint>
    </>
  );
};

export default React.memo(Logo);
