import React, { useMemo, useCallback, useState } from 'react';
import { Circle, dataKey, BodyRef } from 'react-matter-js';
import Letter from './Letter';
import { shuffleWord, sSize } from '../../util';
import { css } from 'emotion';

const Word = ({
  word,
  solution,
  solve,
}: {
  word: string;
  solution: Map<number, BodyRef>;
  solve: any;
}) => {
  const letterY = (sSize.height / 3) * 2;
  const destY = sSize.height / 4;
  const destR = sSize.width * 0.1;
  const destM = destR * 0.1;
  const destW = destR * 2 + destM;
  const destOff = (sSize.width - destW * word.length) / 2;

  const shuffled = useMemo(() => shuffleWord(word), [word]);

  const onDestCollide = useCallback(
    (destBody, letterBody) => {
      const destData = destBody.parent[dataKey];
      const letterData = letterBody.parent[dataKey];

      if (destData.solved || destData?.letter !== letterData.letter) {
        return;
      }
      destData.solved = true;
      solve(letterData.i, destBody);
    },
    [solve],
  );

  return (
    <>
      {
        <svg
          viewBox={`0 0 ${sSize.width} ${sSize.height}`}
          className={css`
            position: absolute;
            top: 0;
            z-index: -1;
          `}
        >
          {[...word].map((letter, i) => (
            <circle
              cx={destOff + destW * i + destW / 2}
              cy={destY}
              r={destR * 0.95}
              className={css`
                fill: transparent;
                stroke: #646464;
                stroke-width: 2px;
                stroke-dasharray: 2, 2;
                stroke-linejoin: round;
              `}
              key={`${letter}-${i}`}
            />
          ))}
        </svg>
      }
      {
        [...shuffled]
          .map((letter, i) => {
            return (
              <Letter
                key={`${letter}-${i}`}
                letter={letter}
                // solved={solution.get(i)}
                x={x(i, shuffled.length)}
                y={letterY}
                data={{ letter, i }}
              />
            );
          })
          .concat(
            [...word].map((letter, i) => {
              return (
                <Circle
                  x={destOff + destW * i + destW / 2}
                  y={destY}
                  radius={destR}
                  options={{
                    label: `dest ${letter}`,
                    isStatic: true,
                    isSensor: true,
                    render: { fillStyle: 'transparent' },
                  }}
                  key={`dest-${i}`}
                  onCollisionStart={onDestCollide}
                  data={{
                    letter,
                    i,
                  }}
                />
              );
            }),
          ) as any
      }
    </>
  );
};

export default React.memo(Word);

export const letterSize = 0.05;
const x = (i: number, n: number) => sSize.width * ((1 / (n + 1)) * (i + 1));
