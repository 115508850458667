import { createSlice } from '@reduxjs/toolkit';

const wordSlice = createSlice({
  name: 'words',
  initialState: {
    words: ['abc', 'efgh', 'bab'],
    currentWord: 0,
    solved: [] as boolean[],
    startedAt: null,
  },
  reducers: {
    solve(state, { payload }) {
      state.solved[payload] = true;
    },
  },
});

export const { solve } = wordSlice.actions;

export default wordSlice.reducer;
