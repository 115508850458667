import React from 'react';
import { Shape } from 'react-matter-js';
import { useLetter } from '../features/words/hooks';
import { sSize, debug } from '../util';

const Bits = () => {
  const shapes = useLetter();
  if (!shapes) {
    return null;
  }

  const bits = get('zal', 4, shapes, 0.025)
    .concat(get('gab', 7, shapes, 0.015))
    .concat(get('bur', 3, shapes, 0.05));

  return bits.map(({ shape, hull, size }, i) => {
    const min = 0.1;
    const max = 1 - min;
    return (
      <Shape
        clone
        x={sSize.width * Math.max(min, Math.min(max, Math.random()))}
        y={sSize.height * Math.max(min, Math.min(max, Math.random()))}
        width={sSize.max * size}
        height={sSize.max * size}
        cats={['bits']}
        hull={hull.shape}
        sprite={shape.shape}
        mass={1}
        options={{
          render: { visible: debug },
          collisionFilter: {
            category: 0x0004,
          },
        }}
        key={`bit-${i}`}
      />
    );
  });
};

export default React.memo(Bits);

const get = (name: string, length: number, shapes: any, size: number) => {
  return Array.from({ length }, (_, i) => {
    const key = `${name}${i + 1}`;

    const shape = shapes.get(key);
    return { shape, hull: shapes.get(`${key}-hull`) || shape, size };
  });
};
