import { useState, useCallback } from 'react';

export const useRerender = () => {
  const [, updateState] = useState();
  return useCallback(() => updateState(Symbol()), []);
};

export const shuffle = <A>(a: A[]): A[] => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};
export const shuffleWord = (input: string): string => {
  if (input.length <= 1) {
    return input;
  }
  const letters = [...input];
  for (;;) {
    const result = shuffle(letters).join('');
    if (result !== input) {
      return result;
    }
  }
};

export const sSize: {
  width: number;
  height: number;
  diagonal: number;
  max: number;
  min: number;
} = {} as any;

{
  const update = () => {
    const {
      clientWidth: width,
      clientHeight: height,
    } = document.documentElement;
    sSize.diagonal = Math.sqrt(width ** 2 + height ** 2);
    Object.assign(sSize, {
      width,
      height,
      max: Math.max(width, height),
      min: Math.min(width, height),
    });
  };
  update();
  window.addEventListener('resize', update);
}

export const debug = window.location.hash === '#debug';
