import React from 'react';
import { css } from 'emotion';
import { WindowScene } from 'react-matter-js';
import Game from './Game';
import { debug } from '../util';

const App = () => {
  return (
    <WindowScene
      walled
      wallWidth={500}
      gravity={{ y: 0 }}
      rendererProps={{
        options: {
          wireframes: debug,
        },
        mouseConstraintOptions: {
          collisionFilter: {
            mask: 0x0002,
          },
          constraint: {
            render: {
              visible: false,
            },
            stiffness: 0.8,
          },
        },
      }}
    >
      <Game />
    </WindowScene>
  );
};

export default App;

document.body.classList.add(css`
  display: flex;
  --a: rgba(255, 255, 255, 0.1);
  --b: rgba(255, 255, 255, 0.05);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  background-image: -webkit-linear-gradient(var(--a) 2px, transparent 2px),
    -webkit-linear-gradient(0, var(--a) 2px, transparent 2px),
    -webkit-linear-gradient(var(--b) 1px, transparent 1px),
    -webkit-linear-gradient(0, var(--b) 1px, transparent 1px);
  background-image: -moz-linear-gradient(var(--a) 2px, transparent 2px),
    -moz-linear-gradient(0, var(--a) 2px, transparent 2px),
    -moz-linear-gradient(var(--b) 1px, transparent 1px),
    -moz-linear-gradient(0, var(--b) 1px, transparent 1px);
  background-image: linear-gradient(var(--a) 2px, transparent 2px),
    linear-gradient(90deg, var(--a) 2px, transparent 2px),
    linear-gradient(var(--b) 1px, transparent 1px),
    linear-gradient(90deg, var(--b) 1px, transparent 1px);
  position: relative;
  height: 100%;
  color: #eee;
}`);
