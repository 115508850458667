import React, { useCallback, useEffect, useRef } from 'react';
import {
  Shape,
  Circle,
  useForwardRef,
  cloneKey,
  useConstraint,
  Position,
  useEngine,
  BodyRef,
} from 'react-matter-js';
import { useLetter } from './hooks';
import { sSize, debug, useRerender } from '../../util';
import { css } from 'emotion';

const Letter = ({ x, y, letter, data, outOfBounds = false, ...props }: any) => {
  const shapeMap = useLetter();
  const letterData = shapeMap?.get(`lett-${letter}`);

  // const maxSize = sSize.max;
  const render: any = {
    visible: debug,
  };
  const shapeRef: BodyRef = useRef();
  const sensorRef: BodyRef = useRef();

  console.log(123, shapeRef.current, sensorRef.current);

  // useConstraint(
  //   {
  //     // stiffness: 0.1,
  //     // length: 0,
  //     // render,
  //   },
  //   true,
  //   { current: dest },
  //   externalBodyRef
  //   );
  useConstraint(
    {
      length: 0,
      render,
    },
    true,
    shapeRef,
    sensorRef,
  );
  const rerender = useRerender();
  useEffect(() => {
    rerender();
  });
  // const { refA: originRef } = useConstraint(
  //   {
  //     length: 0,
  //     damping: 0.1,
  //     render,
  //   },
  //   outOfBounds,
  //   bodyRef,
  // );

  const onMouseDown = useCallback(
    // TODO: fix
    // @ts-ignore
    () => void shapeRef.current[cloneKey].ref.current.classList.add(dragging),
    [shapeRef],
  );
  const onMouseUp = useCallback(
    () =>
      // TODO: fix
      // @ts-ignore
      void shapeRef.current[cloneKey].ref.current.classList.remove(dragging),
    [shapeRef],
  );
  if (!letterData) {
    return null;
  }
  const { shape, sprite } = letterData;
  const { shape: hull } = shapeMap?.get(`hull-${letter}`);
  const size = sSize.max * letterSize;
  // const targetY = sSize.height / 3.5;

  return (
    <>
      <Circle
        x={x}
        y={y}
        radius={size / 2 + 10}
        options={{
          label: `letter-${data.i} sensor`,
          isSensor: true,
          render: {
            visible: debug,
          },
          collisionFilter: {
            category: 0x0002,
          },
        }}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        bodyRef={sensorRef}
        data={data}
      />
      <Position
        x={x}
        y={y}
        radius={sSize.width * 0.115}
        // TODO: relativize
        attrOptions={{ boost: 2e-2, gravityConstant: 2e-4 }}
        options={{
          isStatic: true,
          render: {
            visible: debug,
          },
        }}
        // bodyRef={originRef}
      >
        <Shape
          {...props}
          bodyRef={shapeRef}
          cats={['letter', 'attr']}
          clone
          cloneProps={{
            className: letterStyle,
          }}
          trackStates={{
            dragging,
          }}
          x={x}
          y={y}
          width={size}
          height={size}
          hull={hull || shape}
          sprite={sprite || shape}
          options={{
            label: `letter-${data.i} shape`,
            // collisionFilter: {
            //   category: 0x0002,
            // },
            render: {
              visible: debug,
            },
          }}
          data={data}
          trackAngle
        />
      </Position>
    </>
  );
};

export default Letter;

export const letterSize = 0.155;

const letterStyle = css`
  fill: #e29382 !important;
`;
const dragging = css`
  fill: #f06 !important;
`;
