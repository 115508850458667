import { useRef, useEffect } from 'react';
// import { useCallbackRef, createCallbackRef } from 'use-callback-ref';

import Matter from 'matter-js';

// import { useForwardRef } from './common';
// import { useValueEffect } from './useValue';
import { useEngine } from './engineContext';
import { useForwardRef } from './common';
import { useValueEffect } from './useValue';
import { BodyRef } from 'src/bodies/Body';

// TODO: forward refs
// TODO: delete this
const useConstraint = (
  options: Matter.IConstraintDefinition,
  enabled: boolean = true,
  refA: BodyRef,
  refB: BodyRef,
) => {
  const engine = useEngine();
  // const refA = useForwardRef(_refA);
  // const refB = useForwardRef(_refB);

  console.log(refA.current, refB.current);
  useValueEffect(() => {
    if (!enabled || !refA.current || !refB.current) {
      return;
    }
    const constraint = Matter.Constraint.create({
      bodyA: refA.current!,
      bodyB: refB.current!,
      ...options,
    });
    Matter.World.add(engine.world, constraint);

    return () => {
      console.log('remove', enabled, refA.current, refB.current);
      Matter.World.remove(engine.world, constraint);
    };
  }, [enabled, refA.current, refB.current]);

  // return { refA: refA, refB: refB };
};

export default useConstraint;
