import React, { useState, useCallback, useMemo } from 'react';
import { useImmer } from 'use-immer';
import Word from '../features/words/Word';
import Logo from './Logo';
import Bits from './Bits';
import {
  Pool,
  Circle,
  useJitter,
  useCatAttraction,
  Html,
  useConstraint,
  BodyRef,
} from 'react-matter-js';
import Footer from './Footer';
import { sSize, debug, useRerender } from '../util';
import { css } from 'emotion';
// import { css } from 'emotion';

const Game = ({ words = ['abce', 'bcd'] }) => {
  useJitter('letter', {
    angleJitter: 0.2,
    // angleJitter: 10,
  });
  useJitter('bits', {
    driftX: 1e-8,
    driftY: 1e-8,
    angleJitter: 5e-4,
  });
  useCatAttraction('letter', 'bubble', {
    gravityConstant: 2e-2,
    mass: 0.1,
    minDist: 100,
  });

  const [wordIndex, setWordIndex] = useState(0);
  const nextWord = useCallback(() => {
    setWordIndex(i => (i + 1) % words.length);
  }, [setWordIndex, words.length]);
  const word = words[wordIndex];
  const solution = useMemo(() => new Map<number, any>(), []);
  const rerender = useRerender();

  const solve = useCallback(
    (letterIndex: number, destBody: BodyRef) => {
      solution.set(letterIndex, destBody);
      rerender();
      if (solution.size === word.length) {
        console.log('solved');
      }
    },
    [solution, rerender, word],
  );

  return (
    <>
      <Logo />
      <Bits />
      <Word word={word} solution={solution} solve={solve} />
      <Pool count={75} cats={['bubble']}>
        {[
          (props: any) => (
            <Circle
              {...props}
              radius={1 + Math.random() * 5}
              options={{
                render: {
                  fillStyle: 'rgba(255, 255, 255, .05)',
                  strokeStyle: 'rgba(255, 255, 255, .15)',
                  lineWidth: 0.5,
                },
              }}
              maxSides={5}
            />
          ),
        ]}
      </Pool>
      {/* <Footer /> */}
    </>
  );
};

export default Game;
