import { useEffect, useState } from 'react';
import letterPaths from './letters.svg';
// import { css } from 'emotion';
// import styles from "./Letter.module.scss";

let letterData: Map<string, any> | null = null;
export const useLetter = () => {
  const [, rerender] = useState();

  useEffect(
    () =>
      void fetch(letterPaths).then(async response => {
        const parser = new DOMParser();
        const svg = parser.parseFromString(
          await response.text(),
          'image/svg+xml',
        );

        const els = svg.querySelectorAll('[id]');
        const letters = new Map(
          Array.from(els, shape => {
            const id = shape.getAttribute('id');
            const sprite = id?.startsWith('lett-')
              ? setupLetter(shape as any)
              : null;
            return [id!, { id, shape, sprite }];
          }),
        );

        letterData = letters;
        rerender(true);
      }),
    [],
  );

  return letterData;
};

const setupLetter = (el: SVGElement): SVGElement => {
  // const g = cE('g') as SVGGElement;
  // g.appendChild(el);
  el.removeAttribute('id');
  return el;
  // g.classList.add(parentStyle);
  // const clone = el.cloneNode() as SVGPathElement;
  // el.classList.add(elStyle);
  // clone.classList.add(cloneStyle);
  // g.appendChild(clone);

  // return g;
};

// const cE = (name: string) =>
//   document.createElementNS('http://www.w3.org/2000/svg', name);

// const cloneStyle = css`
//   // position: absolute;
//   // top: 50px;
//   // left: 50px;
//   transform: rotate(calc(var(--angle)));
//   fill: #f60 !important;
//   // display: none;
// `;
// const elStyle = css`
//   fill: #60f !important;
// `;
// const parentStyle = css`
//   transform: scale(1);
// `;
